import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Navigation, Pagination, A11y } from 'swiper'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { useSelector } from 'react-redux'
import { loadUser } from '../../actions/authActions'
import styles from './horizontalSlider.module.scss'
import SliderCard from '../Cards/LiveCard'
import axios from 'axios'
import { APIgetRegEvents, APIeventRegistration, APITimeZoneData } from '../../config/API'
import dynamic from 'next/dynamic'
import { trackEventWithAuthStatus } from '../../utils/mixpanel'

const LoginModal = dynamic(() => import('../Modal/LoginModal'))
const EventRegistration = dynamic(() => import('../Modal/EventRegistration'), {
  ssr: false,
})
const EventRegistrationV1 = dynamic(() => import('../EventModal'), {
  ssr: false,
})

const MasterEventModal = dynamic(() => import('../MasterEventModal'), {
  ssr: false,
})

const QRRedirect = dynamic(() => import('../Modal/MobileRedirectQR'), {
  ssr: false,
})

const MobileRedirect = dynamic(() => import('../Modal/MobileRedirect'), {
  ssr: false,
})

const checkAnyNumbersInString = (str) => {
  // Split the string into an array, remove any empty strings, and trim spaces
  const numberArray = str
    .split(',')
    .map((item) => item.trim())
    .filter(Boolean)

  // Convert the array into a set of numbers
  const numbers = new Set(numberArray.map(Number))

  // Define the required numbers as a set
  const requiredNumbers = new Set([6, 7, 8, 9, 12, 13, 14, 15])

  // Check if any of the required numbers are present in the set
  return [...requiredNumbers].some((num) => numbers.has(num))
}

// import VideoCard from '../Cards/VideoCard'

// there is a separate view for phones and for desktop
const Livestreams = ({ events = [] }) => {
  const router = useRouter()
  const auth = useSelector((state) => state.auth)

  // const utc_add = auth.utc_offset ? auth.utc_offset : '+0530'
  const utc_add = '+0530'
  // const timezone = auth.timezone ? auth.timezone : 'Asia/Kolkata'
  // const [timezone, setTimeZone] = useState('Asia/Kolkata')
  const curTime = moment()
  let isEnableButton = false

  console.log('Auth:', auth)

  const [showQR, setShowQR] = useState(false)
  const handleCloseShowQR = () => setShowQR(false)
  const handleShowShowQR = () => setShowQR(true)

  const [showMobileRedirect, setMobileRedirect] = useState(false)
  const handleCloseMobileRedirect = () => setMobileRedirect(false)
  const handleShowMobileRedirect = () => setMobileRedirect(true)

  const [showLogin, setShowLogin] = useState(false)
  const handleCloseLogin = () => setShowLogin(false)
  const handleShowLogin = () => setShowLogin(true)
  const [registeredEvents, setRegisteredEvents] = useState([])
  const [showRegModal, setShowRegModal] = useState(false)
  const [isClicked, setisClicked] = useState(false)
  const handleCloseRegModal = () => setShowRegModal(false)
  const [formDetails, setFormDetails] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    grade: '',
    curriculum: '',
    school_name: '',
    COI: '',
    city: '',
  })

  // const [timeZoneDetails, setTimeZoneDetails] = useState({})

  // Function to convert time from IST to user's time zone
  function convertTimeFromISTToUserTimeZone(istTime, userTimeZone) {
    // Check if userTimeZone is valid, if not, default to IST
    // if (!momentTZ.tz.zone(userTimeZone)) {
    //   console.log('Should not make it here!')
    //   console.log('Invalid user time zone. Defaulting to IST.')
    //   userTimeZone = 'Asia/Kolkata' // Default to IST
    // }
    // Convert time from IST to user's time zone
    // Convert time from IST to user's time zone
    const userTime = moment.tz(istTime, 'Asia/Kolkata').tz(userTimeZone)
    let timeAbbreviation
    if (userTimeZone === 'Asia/Singapore') {
      timeAbbreviation = 'SST'
    } else {
      timeAbbreviation = momentTZ(userTime).tz(userTimeZone).format('z')
    }
    console.log('timeAbbreviation:', timeAbbreviation)
    return { userTime, timeAbbreviation }
  }

  const [timezone, setTimezone] = useState('Asia/Kolkata')

  useEffect(() => {
    const timezone = momentTZ.tz.guess()

    console.log('timezone with lbr', timezone)
    if (timezone && timezone !== 'Asia/Kolkata' && timezone !== 'Asia/Calcutta') {
      setTimezone(timezone)
    }
  }, [])

  const autoSubmit = async () => {
    let [firstName, ...secondName] = formDetails.name.split(' ')
    secondName = secondName.join(' ')
    let apiBody = {
      email: formDetails.email,
      eventID: eventDetails.ID,
      eventName: eventDetails.NAME,
      firstName: firstName,
      lastName: secondName,
      grade: formDetails.grade,
      countryCode: '',
      phone: formDetails.phoneNumber,
      city: formDetails.city ? formDetails.city : cityDetails.id,
      school_name: formDetails.school_name,
      curriculum: formDetails.curriculum,
      countriesOfInterest: formDetails.COI,
    }
    // console.log('apiBody', apiBody)
    // setsubmitted(true)
    const registerEventDetails = await axios.post(APIeventRegistration, apiBody)
    // console.log(registerEventDetails)
    if (registerEventDetails.data.statusCode === 200) {
      // handleClose()
      router.push(`/event-registration-success/${eventDetails.ZOOMID}`)
      // setShowSuccess(false)
    } else {
      setShowRegModal(true)
    }
  }
  useEffect(() => {
    if (isClicked) {
      if (
        checkAnyNumbersInString(eventDetails?.GRADES || '') &&
        formDetails.email != '' &&
        formDetails.name != '' &&
        formDetails.grade != '' &&
        formDetails.phoneNumber != '' &&
        formDetails.curriculum != '' &&
        formDetails.school_name != '' &&
        formDetails.city != '' &&
        formDetails.email &&
        formDetails.name &&
        formDetails.grade &&
        formDetails.phoneNumber &&
        formDetails.curriculum &&
        formDetails.school_name &&
        formDetails.city
      ) {
        // console.log("Auto submitting")
        autoSubmit()
      } else {
        setShowRegModal(true)
      }
    }

    // else{
    //   // seteventDetails(event)

    // }
  }, [formDetails])
  const handleShowRegModal = (event) => {
    setisClicked(true)
    seteventDetails(event)
    if (!auth.isAuthenticated) {
      // seteventDetails(event)
      setShowRegModal(true)
    }
    setFormDetails({
      name:
        auth.userDetails.FIRSTNAME !== undefined ? `${auth.userDetails?.FIRSTNAME} ${auth?.userDetails?.LASTNAME}` : '',
      // phoneNumber: auth.userDetails.PHONENUMBER,
      phoneNumber:
        auth.userDetails.PHONENUMBER || auth.userDetails.COUNTRY_CODE
          ? auth.userDetails.PHONENUMBER && auth.userDetails.PHONENUMBER.includes('+')
            ? auth.userDetails.PHONENUMBER
            : auth.userDetails.COUNTRY_CODE.includes('+')
            ? `${auth.userDetails.COUNTRY_CODE}${auth.userDetails.PHONENUMBER}`
            : `+${auth.userDetails.COUNTRY_CODE}${auth.userDetails.PHONENUMBER}`
          : '',
      grade: auth.userDetails.GRADE,
      curriculum: auth.userDetails?.CURRICULUM,
      email: auth.user ? auth.user : '',
      school_name: auth.userDetails?.SCHOOL,
      city: parseInt(auth.userDetails?.CITY),
      COI: auth?.countries?.map((item) => String(item.TAG_ID)),
    })
    // if(!(formDetails.email != '' && formDetails.name != '' && formDetails.grade != '' && formDetails.phoneNumber != '' && formDetails.curriculum != '' &&
    //   formDetails.school_name != '' && formDetails.city != '' && formDetails.email && formDetails.name && formDetails.grade && formDetails.phoneNumber && formDetails.curriculum &&
    //   formDetails.school_name && cityDetails))
    //   {
    //     setShowRegModal(true)
    //   }

    // setShowRegModal(true)
  }
  const [eventDetails, seteventDetails] = useState(false)

  useEffect(() => {
    const getRegEvents = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
      const regEvents = await axios.get(`${APIgetRegEvents}${auth.userDetails.ID}`, config)
      setRegisteredEvents(regEvents.data.events)
      // console.log("regEvents",regEvents)
    }
    // console.log("regAuth",auth)
    if (auth.userDetails.ID) {
      getRegEvents()
    }
  }, [auth])

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleShowError = () => {
    setShowError(true)
    setErrorMessage('Upgrade to CollegePass+ to view sessions on your laptop/desktop.')
  }
  const handleCloseError = () => {
    setShowError(false)
    setErrorMessage('')
  }

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="upcoming-live pb-0">
            <Row>
              <Col>
                <h1
                  className="text-left  pb-0"
                  style={{
                    marginLeft: '1%',
                  }}
                >
                  Live Streams
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className={styles.cardSliderForDesktop}>
                <Swiper
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={20}
                  slidesPerView={4.1}
                  navigation
                  //pagination={{ clickable: true }}
                  //scrollbar={{draggable: true}}
                  loop={true}
                >
                  {events.map((event, idx) => {
                    // LOOP FOR EACH EVENT
                    // console.log(event)
                    // logic to be used to push to play video or to be pushed to zoom component

                    let topLeftText = 'Live Stream'
                    let topRightText = 'Plus'

                    const timezoneDetails =
                      timezone && timezone !== 'Asia/Kolkata' && timezone !== 'Asia/Calcutta' && event?.DATE_TIME
                        ? convertTimeFromISTToUserTimeZone(
                            moment
                              .utc(
                                event.DATE_TIME
                                  ? event.DATE_TIME
                                  : event.EVENT_DATE_TIME
                                  ? event.EVENT_DATE_TIME
                                  : event.DATE
                              )
                              .utcOffset('+0530'),
                            // timeZoneDetails.timezone
                            timezone
                          )
                        : null
                    // const eventDate = moment.utc(event.DATE_TIME).utcOffset(utc_add).format('dddd, MMMM DD')
                    const eventDate = moment.utc(event.DATE_TIME).utcOffset(utc_add)

                    const eventTime = moment
                      .utc(
                        event.DATE_TIME ? event.DATE_TIME : event.EVENT_DATE_TIME ? event.EVENT_DATE_TIME : event.DATE
                      )
                      .utcOffset(utc_add)
                      .format('hh:mm A')

                    const enableTime = moment.utc(event.DATE_TIME).subtract(10, 'minute').format()

                    const stopTime = moment.utc(event.DATE_TIME).add(2, 'h').format()

                    const event_date_time = moment.utc(event.DATE_TIME).utcOffset(utc_add)
                    let liveStatus =
                      curTime.isSameOrAfter(event_date_time.subtract(10, 'minute').format()) &&
                      curTime.isBefore(event_date_time.add(30, 'minute').format())
                    //  let liveStatus = curTime.isBetween(enableTime, stopTime)
                    //   console.log(event.NAME)
                    //   console.log("curr time",curTime)
                    //   console.log("event time",event_date_time)
                    //  console.log("start time",enableTime)
                    //  console.log("end time",event_date_time.add(50, 'minute').format())

                    isEnableButton = curTime.isBetween(enableTime, stopTime)
                    let isOver = curTime.isAfter(stopTime)

                    let alreadyRegistered = false

                    if (registeredEvents?.length > 0) {
                      alreadyRegistered = registeredEvents?.find((regEve) => {
                        return regEve.ZOOMID === event.ZOOMID
                      })
                    }

                    let cardButton = (
                      <Button
                        disabled={alreadyRegistered}
                        onClick={() => {
                          // handleShowShowQR()
                          handleShowRegModal(event)
                          // trackEventWithAuthStatus(auth, 'click_plus_icon_livestreams_plus_page_web', {
                          //   eventId: event.ID,
                          // })
                          // router.push(`/registration/${event?.ZOOMID}`)
                        }}
                      >
                        {alreadyRegistered ? 'REGISTERED' : 'REGISTER'}
                      </Button>
                    )

                    //account trial = true means there are on free tier
                    //account trial = false means they have exhausted free tier
                    if (isEnableButton === true && !auth.isAuthenticated) {
                      cardButton = (
                        <Button
                          onClick={() => {
                            // handleShowShowQR()
                            handleShowLogin()
                          }}
                        >
                          JOIN NOW
                        </Button>
                      )
                    } else {
                      if (
                        isEnableButton === true &&
                        // (auth.accountTrial === true ||
                        // auth.userDetails?.ISPREMIUM >= event.PREMIUM_LEVEL)
                        auth.userDetails?.ISPREMIUM >= 2
                        // )
                      ) {
                        //if event is starting in 15 min and user is prime+
                        cardButton = (
                          <Button
                            onClick={() => {
                              // handleShowShowQR()
                              router.push(`/live-event/${event?.ZOOMID}`)
                            }}
                          >
                            JOIN NOW
                          </Button>
                        )
                      } else if (
                        isEnableButton === true &&
                        // auth.userDetails.ISPREMIUM < event.PREMIUM_LEVEL
                        auth.userDetails.ISPREMIUM < 2
                      ) {
                        //if event is starting in 15 min and user is not prime nor within free trial tier
                        cardButton = (
                          <Button
                            onClick={(e) => {
                              // handleShowShowQR()
                              // router.push(`/pricing`)
                              handleShowError()
                            }}
                          >
                            JOIN NOW
                          </Button>
                        )
                      } else {
                        //default Button, when the stream is not started
                        // check if event.ID is present in
                        cardButton = (
                          <Button
                            disabled={alreadyRegistered || isOver}
                            onClick={() => {
                              // trackEventWithAuthStatus(auth, 'click_plus_icon_livestreams_plus_page_web', {
                              //   eventId: event.ID,
                              // })
                              // handleShowShowQR()
                              handleShowRegModal(event)
                            }}
                          >
                            {isOver
                              ? 'RECORDING WILL BE AVAILABLE IN FEW DAYS'
                              : alreadyRegistered
                              ? 'REGISTERED'
                              : 'REGISTER'}
                          </Button>
                        )
                      }
                    }
                    if (event.ID == 820) {
                      event.s3_image = '/tempBanners/event-820.jpg'
                    }
                    if (event.ID == 810) {
                      event.s3_image = '/tempBanners/event-810.jpg'
                    }
                    if (event.ID == 825) {
                      event.s3_image = '/tempBanners/event-825.jpg'
                    }
                    if (event.ID == 821) {
                      event.s3_image = '/tempBanners/event-821.jpg'
                    }
                    if (event.ID == 811) {
                      event.s3_image = '/tempBanners/event-811.jpg'
                    }
                    if (event.ID == 823) {
                      event.s3_image = '/tempBanners/event-823.jpg'
                    }
                    if (event.ID == 822) {
                      event.s3_image = '/tempBanners/event-822.jpg'
                    }

                    console.log('card button', timezoneDetails)
                    return (
                      <SwiperSlide key={idx}>
                        <SliderCard
                          eventid={event.ID}
                          imageSrc={event.s3_image}
                          alt={event.NAME}
                          eventTitle={event.NAME.length > 50 ? `${event.NAME.slice(0, 50)}...` : event.NAME}
                          eventDate={
                            eventDate.format('DD MMMM') +
                            '' +
                            // ` | ${eventTime} (IST)`+
                            `${
                              timezoneDetails?.timeAbbreviation
                                ? ` | ${timezoneDetails.userTime.format('hh:mm A')} (${
                                    timezoneDetails?.timeAbbreviation
                                  })`
                                : ` | ${eventTime} (IST)`
                            }`
                          }
                          eventTime={
                            eventDate.format('dddd, MMMM DD, ') +
                            `${
                              timezoneDetails?.timeAbbreviation
                                ? `${timezoneDetails.userTime.format('hh:mm A')} (${timezoneDetails?.timeAbbreviation})`
                                : `${eventTime} (IST)`
                            }`
                          }
                          topLeftText={topLeftText}
                          topRightText={topRightText}
                          cardButton={cardButton}
                          eventDescription={event.DESC}
                          eventVideo={event.VIDEO_URL}
                          liveStatus={liveStatus}
                          premiumLevel={event.PREMIUM_LEVEL}
                          userid={auth.userDetails.ID}
                          grades={event.GRADES}
                          showLogin={handleShowLogin}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </Col>

              {/* LOGIC FOR MOBILE - AS SLIDER NEEDS TO BE REDUCED */}
              <Col className={styles.cardSliderForMobile}>
                <Swiper
                  modules={[Pagination, A11y]}
                  spaceBetween={50}
                  slidesPerView={1.3}
                  //pagination={{ clickable: true }}
                  //scrollbar={{draggable: true}}
                  loop={true}
                >
                  {events.map((event, idx) => {
                    let topLeftText = 'Live Stream'
                    let topRightText = 'Plus'

                    const timezoneDetails =
                      timezone && timezone !== 'Asia/Kolkata' && timezone !== 'Asia/Calcutta' && event?.DATE_TIME
                        ? convertTimeFromISTToUserTimeZone(
                            moment
                              .utc(
                                event.DATE_TIME
                                  ? event.DATE_TIME
                                  : event.EVENT_DATE_TIME
                                  ? event.EVENT_DATE_TIME
                                  : event.DATE
                              )
                              .utcOffset('+0530'),
                            // timeZoneDetails.timezone
                            timezone
                          )
                        : null

                    const eventDate = moment.utc(event.DATE_TIME).utcOffset(utc_add)

                    const eventTime = moment
                      .utc(
                        event.DATE_TIME ? event.DATE_TIME : event.EVENT_DATE_TIME ? event.EVENT_DATE_TIME : event.DATE
                      )
                      .utcOffset(utc_add)
                      .format('hh:mm A')

                    const enableTime = moment.utc(event.DATE_TIME).subtract(10, 'minute').format()

                    const stopTime = moment.utc(event.DATE_TIME).add(2, 'h').format()

                    const event_date_time = moment.utc(event.DATE_TIME).utcOffset(utc_add)
                    let liveStatus =
                      curTime.isSameOrAfter(event_date_time.subtract(10, 'minute')) &&
                      curTime.isBefore(event_date_time.add(30, 'minute'))

                    let isOver = curTime.isAfter(stopTime)

                    isEnableButton = curTime.isBetween(enableTime, stopTime)
                    let alreadyRegistered = false

                    if (registeredEvents?.length > 0) {
                      alreadyRegistered = registeredEvents?.find((regEve) => {
                        return regEve.ZOOMID === event.ZOOMID
                      })
                    }

                    let cardButton = (
                      <Button
                        disabled={alreadyRegistered}
                        onClick={() => {
                          // handleShowMobileRedirect()
                          handleShowRegModal(event)
                          // trackEventWithAuthStatus(auth, 'click_plus_icon_livestreams_plus_page_web', {
                          //   eventId: event.ID,
                          // })

                          // router.push(`/registration/${event?.ZOOMID}`)
                        }}
                      >
                        {alreadyRegistered ? 'REGISTERED' : 'REGISTER'}
                      </Button>
                    )

                    //account trial = true means there are on free tier
                    //account trial = false means they have exhausted free tier
                    if (isEnableButton === true && !auth.isAuthenticated) {
                      cardButton = (
                        <Button
                          onClick={() => {
                            // handleShowMobileRedirect()
                            handleShowLogin()
                          }}
                        >
                          JOIN NOW
                        </Button>
                      )
                    } else {
                      if (
                        isEnableButton === true &&
                        (auth.accountTrial === true || auth.userDetails?.ISPREMIUM >= event.PREMIUM_LEVEL)
                      ) {
                        //if event is starting in 15 min and user is prime+
                        cardButton = (
                          <Button
                            onClick={() => {
                              // handleShowMobileRedirect()
                              router.push(`/live-event/${event?.ZOOMID}`)
                            }}
                          >
                            JOIN NOW
                          </Button>
                        )
                      } else if (isEnableButton === true && auth.userDetails?.ISPREMIUM < event.PREMIUM_LEVEL) {
                        //if event is starting in 15 min and user is not prime nor within free trial tier
                        cardButton = (
                          <Button
                            onClick={(e) => {
                              // handleShowMobileRedirect()
                              // router.push(`/pricing`)
                              handleShowError()
                            }}
                          >
                            JOIN NOW
                          </Button>
                        )
                      } else {
                        //default Button, when the stream is not started
                        // check if event.ID is present in
                        cardButton = (
                          <Button
                            disabled={alreadyRegistered || isOver}
                            onClick={() => {
                              // trackEventWithAuthStatus(auth, 'click_plus_icon_livestreams_plus_page_web', {
                              //   eventId: event.ID,
                              // })
                              // handleShowMobileRedirect()
                              // router.push(`/registration/${event?.ZOOMID}`)
                              handleShowRegModal(event)
                            }}
                          >
                            {isOver
                              ? 'RECORDING WILL BE AVAILABLE IN FEW DAYS'
                              : alreadyRegistered
                              ? 'REGISTERED'
                              : 'REGISTER'}
                          </Button>
                        )
                      }
                    }
                    if (event.ID == 820) {
                      event.s3_image = '/tempBanners/event-820.jpg'
                    }
                    if (event.ID == 810) {
                      event.s3_image = '/tempBanners/event-810.jpg'
                    }
                    if (event.ID == 825) {
                      event.s3_image = '/tempBanners/event-825.jpg'
                    }
                    if (event.ID == 821) {
                      event.s3_image = '/tempBanners/event-821.jpg'
                    }
                    if (event.ID == 811) {
                      event.s3_image = '/tempBanners/event-811.jpg'
                    }
                    if (event.ID == 823) {
                      event.s3_image = '/tempBanners/event-823.jpg'
                    }
                    if (event.ID == 822) {
                      event.s3_image = '/tempBanners/event-822.jpg'
                    }
                    return (
                      <SwiperSlide key={idx}>
                        <SliderCard
                          eventid={event.ID}
                          imageSrc={event.s3_image}
                          // imageSrc={'/upenn-event-card-test.png'}
                          alt={event.NAME}
                          eventTitle={event.NAME.length > 50 ? `${event.NAME.slice(0, 50)}...` : event.NAME}
                          eventDate={
                            eventDate.format('DD MMMM') +
                            '' +
                            // ` | ${eventTime} (IST)`+
                            `${
                              timezoneDetails?.timeAbbreviation
                                ? ` | ${timezoneDetails.userTime.format('hh:mm A')} (${
                                    timezoneDetails?.timeAbbreviation
                                  })`
                                : ` | ${eventTime} (IST)`
                            }`
                          }
                          eventTime={
                            eventDate.format('dddd, MMMM DD, ') +
                            `${
                              timezoneDetails?.timeAbbreviation
                                ? `${timezoneDetails.userTime.format('hh:mm A')} (${timezoneDetails?.timeAbbreviation})`
                                : `${eventTime} (IST)`
                            }`
                          }
                          topLeftText={topLeftText}
                          topRightText={topRightText}
                          cardButton={cardButton}
                          eventDescription={event.DESC}
                          eventVideo={event.VIDEO_URL}
                          liveStatus={liveStatus}
                          premiumLevel={event.PREMIUM_LEVEL}
                          userid={auth.userDetails.ID}
                          grades={event.GRADES}
                          showLogin={handleShowLogin}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <LoginModal show={showLogin} handleClose={handleCloseLogin} />
      {/* <EventRegistration */}
      {showRegModal ? (
        checkAnyNumbersInString(eventDetails?.GRADES || '') ? (
          <MasterEventModal show={showRegModal} handleClose={handleCloseRegModal} eventDetails={eventDetails} />
        ) : (
          <EventRegistrationV1 show={showRegModal} handleClose={handleCloseRegModal} eventDetails={eventDetails} />
        )
      ) : null}
      <QRRedirect showLinkModal={showQR} handleCloseLinkModal={handleCloseShowQR} />
      <MobileRedirect showLinkModal={showMobileRedirect} handleCloseLinkModal={handleCloseMobileRedirect} />

      {showError ? (
        <Modal className="device-modal" show={showError} onHide={handleCloseError}>
          <Modal.Body>
            <Row>
              <Col
                style={{
                  textAlign: 'center',
                  letterSpacing: '0.03rem',
                  fontSize: '17px',
                  color: '#ffffff',
                }}
              >
                {errorMessage}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleCloseError()} style={{ cursor: 'pointer' }}>
              Cancel
            </Button>
            <Button
              // onClick={() => router.push('/pricing')}
              onClick={() => router.push('/contact')}
              style={{ cursor: 'pointer' }}
            >
              Upgrade
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </Fragment>
  )
}

export default Livestreams
